import React from "react"

const ProjectCard = ({ data }) => {
  return (
    <>
      <div className="col-12 mb-4">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">{data.company}</h5>
          </div>
          <ul className="list-group list-group-flush">
            <li className="list-group-item">Client: {data.client}</li>
            <li className="list-group-item">Project: {data.project}</li>
            <li className="list-group-item">Duration: {data.duration}</li>
            <li className="list-group-item">Role: {data.role}</li>
            <li className="list-group-item">Team Size: {data.teamSize}</li>
            <li className="list-group-item">Environment/Technologies: {data.env}</li>
          </ul>
          <div className="card-body">
            <h5>Project Description:</h5>
            <p>{data.description}</p>
            <h5>Roles & Responsibilities:</h5>
            <ul>
              {data.responsibilities.map((responsibility, index) => (
                <li key={index}>{responsibility}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProjectCard
