import heroImage from "../assets/images/hero-image1.png"
import resumePdf from "../assets/FrontendDeveloperLead_RajaSekar_8903058888_2023v2.pdf"
import { Link } from "react-router-dom"
const Home = () => {
  const homeData = {
    title: "Senior Frontend Developer",
    name: "Raja Sekar",
    description: "Hello and thank you for visiting my portfolio. I'm thrilled to have the opportunity to showcase my journey and skills as a senior frontend developer. With a passion for both frontend and backend development, I've embarked on a quest to create seamless, user-centered web applications that bring ideas to life.",
  }

  return (
    <>
      <div className="container col-xxl-8 px-2">
        <div className="row flex-lg-row-reverse align-items-center min-vh-100 g-5 py-5">
          <div className="col-10 col-sm-8 col-lg-6">
            <img src={heroImage} class="d-block mx-lg-auto img-fluid" alt="Raja Sekar" width="700" height="500" loading="lazy" />
          </div>
          <div className="col-lg-6">
            <h1 className="fs-6 text-uppercase">{homeData.title}</h1>
            <h1 className="display-5 fw-bold lh-1 mb-3">
              <span className="fw-lighter">👋 Hi, I'm </span>
              {homeData.name}
            </h1>
            <p className="fst-normal">{homeData.description}</p>
            <div className="d-grid gap-2 d-md-flex justify-content-md-start">
              <Link to={resumePdf} className="btn btn-primary px-4 me-md-2 text-uppercase" target="_blank">
                My Resume
              </Link>
              <Link to="/contact" className="btn btn-light px-4 me-md-2 text-uppercase">
                Contact
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Home
