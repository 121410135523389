import React from "react"
import { Link } from "react-router-dom"

export const Footer = () => {
  return (
    <>
      {/* Footer */}

      <footer className="footer mt-auto py-3 bg-body-tertiary">
        <div className="container col-xxl-8 d-flex justify-content-between">
          <p className="mb-0">
            <small className="text-body-secondary">
              <Link className="link-secondary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover" to="https://linkedin.com/in/RajaSekarInit" target="_blank">
                LinkedIn
              </Link>{" "}
              |{" "}
              <Link className="link-secondary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover" to="https://wa.me/+918903058888" target="_blank">
                WhatsApp
              </Link>
            </small>
          </p>
          <p className="mb-0">Built with React ❤️</p>
        </div>
      </footer>
    </>
  )
}
