import React from "react"
import { Link } from "react-router-dom"
import ContactForm from "../Components/ContactForm"

export const Contact = () => {
  return (
    <>
      <header className="page-title container col-xxl-8 mb-4 mt-4">
        <h2>Contact Me</h2>
      </header>

      <div className="container col-xxl-8 mt-3">
        <div className="row">
          <div className="col-md-8">
            <p>
              Feel free to reach out to me via email at <Link to="emailto:rajasekarinit@gmail.com">rajasekarinit@gmail.com</Link> or connect with me on LinkedIn at <Link to="https://linkedin.com/in/RajaSekarInit">https://linkedin.com/in/RajaSekarInit</Link>
            </p>
          </div>
        </div>
      </div>

      <div className="container col-xxl-8 mt-3">
        <div className="row">
          <ContactForm />
        </div>
      </div>
    </>
  )
}
