import React from "react"
import ProjectCard from "../Components/ProjectCard"

export const AboutMe = () => {
  // Define the JSON data within the component
  const aboutMeData = {
    pageTitle: "About Me",
    professionalSummary: "Experienced and highly skilled Senior Front-End Developer with over 14+ years of expertise in crafting exceptional user interfaces and seamless user experiences. Adept at translating design concepts into interactive web applications with a strong focus on performance, accessibility, and scalability. Proven ability to collaborate with cross-functional teams and drive projects from conception to completion. Committed to staying at the forefront of front-end technologies and continuously improving development processes.",
    keySkills: [
      {
        title: "Front-End Technologies",
        description: "HTML5, CSS3, JavaScript (ES6+), TypeScript",
      },
      {
        title: "Frameworks & Libraries",
        description: "React, Angular, Vue.js",
      },
      {
        title: "Responsive Design",
        description: "Bootstrap, Material UI, Flexbox, CSS Grid",
      },
      {
        title: "Version Control",
        description: "Git, SVN",
      },
      {
        title: "Package Managers",
        description: "npm, yarn",
      },
      {
        title: "Build Tools",
        description: "Webpack, Gulp, Grunt",
      },
      {
        title: "Testing",
        description: "Jest, Enzyme, Jasmine",
      },
      {
        title: "API Integration",
        description: "RESTful APIs, GraphQL",
      },
      {
        title: "State Management",
        description: "Redux, Mobx, Vuex",
      },
      {
        title: "Code Quality",
        description: "ESLint, Prettier, TSLink",
      },
      {
        title: "Web Accessibility",
        description: "WCAG, ARIA Roles",
      },
      {
        title: "Performance Optimization",
        description: "Lazy loading, code splitting, bundle analysis",
      },
      {
        title: "Cross-Browser Compatibility",
        description: "Chrome, Firefox, Safari, Edge",
      },
      {
        title: "Responsive Web Design",
        description: "Mobile-first development",
      },
      {
        title: "UI/UX Principles",
        description: "User-centered design, usability testing",
      },
      {
        title: "Prototyping",
        description: "Figma, Sketch, Adobe XD, Photoshop, AxureRP",
      },
      {
        title: "Version Control",
        description: "Git, Bitbucket, Sourcetree",
      },
      {
        title: "Deployment",
        description: "CI/CD Pipelines, AWS, Netlify, Heroku",
      },
    ],
    education: [
      {
        degree: "B. Tech (Information Technology)",
        year: "2005",
        institution: "Sona College of Technology, Salem, Tamil Nadu",
      },
      {
        degree: "12th Std",
        year: "2000",
        institution: "Sri Jothi Bala Mandir Hr. Secondary School, Salem, Tamil Nadu",
      },
      {
        degree: "10th Std",
        year: "1998",
        institution: "Sri Vidya Mandir Hr. Secondary School, Salem, Tamil Nadu.",
      },
    ],
    workExperiences: [
      {
        company: "LiquidHub India, Bengaluru, India",
        year: "2016 - 2020",
        position: "Senior Front End Developer & UI Lead",
      },
      {
        company: "Mindtree Limited, Bengaluru, India",
        year: "2014 - 2015",
        position: "Front End Developer",
      },
      {
        company: "Fulltime Freelance, Salem, Tamilnadu, India",
        year: "2007 - 2012",
        position: "Doing Freelance projects with web design agencies in USA, Australia, Singapore",
      },
    ],
    projectData: [
      {
        company: "LiquidHub India Pvt Ltd., Bengaluru, India.",
        client: "EagleView Technology Corporation",
        project: "EagleView Bonfire",
        duration: "June 2019 – April 2020",
        role: "UI Lead",
        teamSize: "12-14",
        env: "ReactJS, React Bootstrap, HTML5, CSS3, SASS, JavaScript ES6.",
        description: "Developed an internal CRM App for EagleView, a technology provider of aerial imagery and geographic information system solutions. The CRM tracked and maintained leads and sales reports.",
        responsibilities: ["Developed CRM using ReactJS, React Bootstrap, HTML5, CSS3, SASS, JavaScript ES6.", "Resolved cross-device issues and tested for mobile & tablets.", "Collaborated with product managers and backend developers to meet UI requirements."],
      },
      {
        company: "LiquidHub India Pvt Ltd., Bengaluru, India.",
        client: "McDonald's - USA",
        project: "McDonald's USA Website Maintenance",
        duration: "Oct 2018 – May 2019",
        role: "UI Lead",
        teamSize: "9-10",
        env: "AEM (Adobe Experience Manager), HTML5, CSS3, Sass, JavaScript, jQuery.",
        description: "Maintained and updated McDonald's USA website with new pages and promotional content using AEM.",
        responsibilities: ["Maintained the website using AEM, HTML5, CSS3, Sass.", "Ensured compatibility across devices and fixed issues.", "Collaborated with product managers and Java developers to fulfill UI requirements."],
      },
      {
        company: "LiquidHub India Pvt Ltd., Bengaluru, India.",
        client: "Novartis Erenumab Digital Solution",
        project: "Aimovig – Mobile Application Development",
        duration: "Jan 2018 - Oct 2018",
        role: "UI Lead",
        teamSize: "9-10",
        env: "Angular5, NativeScript, SASS, Typescript, HTML5/CSS3.",
        description: "Developed a patient-facing mobile application for Novartis to educate migraine sufferers about Aimovig treatment.",
        responsibilities: ["Developed the mobile app using Angular5, NativeScript, SASS, Typescript, HTML5/CSS3.", "Ensured compatibility across devices and fixed issues.", "Collaborated with product managers and Java developers to meet UI requirements."],
      },
      {
        company: "LiquidHub India Pvt Ltd., Bengaluru, India.",
        client: "Tufts Health Plan",
        project: "Tufts Health Plan Hybrid Mobile Application",
        duration: "April 2017 – Dec 2017",
        role: "UI Lead",
        teamSize: "7-8",
        env: "Angular2, Ionic2, SASS, Typescript, HTML5/CSS3.",
        description: "Developed a hybrid mobile application for Tufts Health Plan to reach out to mobile and digital-savvy customers.",
        responsibilities: ["Developed the hybrid mobile app using Angular2, Ionic2, SASS, Typescript, HTML5/CSS3.", "Ensured cross-device compatibility and resolved issues.", "Collaborated with product managers and Java developers to meet UI requirements."],
      },
      {
        company: "LiquidHub India Pvt Ltd., Bengaluru, India.",
        client: "Wells Fargo",
        project: "Wells Fargo Mobile Banking Application",
        duration: "May 2016 – March 2017",
        role: "UI Lead",
        teamSize: "20-25",
        env: "HTML5, CSS3, JavaScript, jQuery, jQuery Mobile, Java, Oracle.",
        description: "Developed a Mobile Wallet for Wells Fargo.",
        responsibilities: ["Developed the mobile wallet using HTML5, CSS3, JavaScript, jQuery, jQuery Mobile, Java, Oracle.", "Ensured cross-device compatibility and resolved issues.", "Collaborated with product managers and Java developers to meet UI requirements."],
      },
      {
        company: "Mindtree Ltd., Bengaluru, India.",
        client: "Novartis",
        project: "SlowFe",
        duration: "Jan 2015 – May 2015",
        role: "UI Developer",
        teamSize: "12-15",
        env: "Photoshop, HTML5, CSS3, JavaScript, jQuery, .NET Framework.",
        description: "Developed a responsive website for SlowFe, a slow-release iron supplement product by Novartis.",
        responsibilities: ["Developed the website from PSD using HTML5, CSS3, JavaScript, jQuery, Bootstrap.", "Ensured cross-device compatibility and resolved issues.", "Collaborated with product managers and .Net developers to meet UI requirements."],
      },
      {
        company: "Mindtree Ltd., Bengaluru, India.",
        client: "Novartis",
        project: "Theraflu Poland",
        duration: "Oct 2014 – Jan 2015",
        role: "UI Developer",
        teamSize: "12-15",
        env: "Photoshop, HTML5, CSS3, JavaScript, jQuery, .NET Framework.",
        description: "Developed a responsive website for Theraflu Poland, supplement product by Novartis.",
        responsibilities: ["Developed the website from PSD using HTML5, CSS3, JavaScript, jQuery, Bootstrap.", "Ensured cross-device compatibility and resolved issues.", "Collaborated with product managers and .Net developers to meet UI requirements."],
      },
    ],
  }

  return (
    <>
      <header className="page-title container col-xxl-8 mb-4 mt-4">
        <h2>{aboutMeData.pageTitle}</h2>
      </header>

      {/* Professional Summary */}
      <div className="container col-xxl-8 mb-4">
        <div className="row mb-2">
          <h2>Professional Summary</h2>
        </div>
        <div className="row">
          <p>{aboutMeData.professionalSummary}</p>
        </div>
      </div>

      {/* Key Skills */}
      <div className="container col-xxl-8 mb-4">
        <div className="row mb-2">
          <h2>Key Skills</h2>
        </div>
        <div className="row">
          {/* Render key skills dynamically */}
          {aboutMeData.keySkills.map((Keyskills, index) => (
            <div className="col-sm-3 col-md-2" key={index}>
              <dl>
                <dt>{Keyskills.title}</dt>
                <dd>{Keyskills.description}</dd>
              </dl>
            </div>
          ))}
        </div>
      </div>

      {/* Education */}
      <div className="container col-xxl-8 mb-2">
        <div className="row">
          <div className="col">
            <h2>Education & Professional Qualifications</h2>
          </div>
        </div>
      </div>

      <div className="container col-xxl-8 mb-4">
        <div className="list-group">
          {/* Render education dynamically */}
          {aboutMeData.education.map((edu, index) => (
            <p className="list-group-item list-group-item-action mb-0" key={index}>
              <div className="d-flex w-100 justify-content-between">
                <h5 className="mb-1">{edu.degree}</h5>
                <small>Year: {edu.year}</small>
              </div>
              <p className="mb-1">{edu.institution}</p>
            </p>
          ))}
        </div>
      </div>

      {/* Work Experiences */}

      <div className="container col-xxl-8 mb-2">
        <div className="row">
          <div className="col">
            <h2>Work Experiences</h2>
          </div>
        </div>
      </div>

      <div className="container col-xxl-8 mb-4">
        <div className="list-group">
          {/* Render Work Experiences Dynamically */}
          {aboutMeData.workExperiences.map((work, index) => (
            <p className="list-group-item list-group-item-action mb-0" key={index}>
              <div className="d-flex w-100 justify-content-between">
                <h5 className="mb-1">{work.company}</h5>
              </div>
              <small>{work.year}</small>
              <p className="mb-1">{work.position}</p>
            </p>
          ))}
        </div>
      </div>

      {/* Project Profiles */}

      <div className="container col-xxl-8 mb-2">
        <div className="row">
          <div className="col">
            <h2>Project Profiles</h2>
          </div>
        </div>
      </div>
      <div className="container col-xxl-8 mb-2">
        <div className="row">
          {aboutMeData.projectData.map((project, index) => (
            <ProjectCard key={index} data={project} />
          ))}
        </div>
      </div>
    </>
  )
}
