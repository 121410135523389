import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/images/logo.png";
const Navbar = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <nav class="navbar navbar-expand-lg sticky-top navbar-dark bg-primary">
        <div class="container col-xxl-8">
          <a class="navbar-brand font-weight-bold" href="/">
            <img src={Logo} alt="Logo" width="180" height="auto" />
          </a>
          <button
            class="navbar-toggler"
            onClick={toggleMenu}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div
            class={`collapse navbar-collapse justify-content-end ${
              isMenuOpen ? "show" : ""
            }`}
            id="navbarNav"
          >
            <ul class="navbar-nav ml-auto">
              <li class="nav-item">
                <Link
                  to="/"
                  class="nav-link text-uppercase"
                  onClick={toggleMenu}
                >
                  Home
                </Link>
              </li>
              <li class="nav-item">
                <Link
                  to="/aboutme"
                  class="nav-link  text-uppercase"
                  onClick={toggleMenu}
                >
                  About Me
                </Link>
              </li>
              {/* <li class="nav-item">
                <Link to="/projects" class="nav-link  text-uppercase" onClick={toggleMenu}>
                  Projects
                </Link>
              </li> */}
              <li class="nav-item">
                <Link
                  to="/contact"
                  class="nav-link text-uppercase"
                  onClick={toggleMenu}
                >
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
