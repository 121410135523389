import "./App.scss"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import Navbar from "./Pages/Navbar"
import "bootstrap/dist/css/bootstrap.css" // Import Bootstrap CSS
import "bootstrap/dist/js/bootstrap.bundle.min.js" // Import Bootstrap JavaScript
import "@popperjs/core" // Import Popper.js

import Home from "./Pages/Home"
import { AboutMe } from "./Pages/Aboutme"
import { Projects } from "./Pages/Projects"
import { Contact } from "./Pages/Contact"
import { Footer } from "./Components/Footer"
// import Navbar from "./Pages/Navbar"

function App() {
  return (
    <Router>
      <div className="d-flex flex-column min-vh-100">
        <Navbar />
        <div className="container-fluid flex-grow  mb-3">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/aboutme" element={<AboutMe />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  )
}

export default App
