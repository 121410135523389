// src/components/ContactForm.js
import React, { useState } from "react"
import axios from "axios"

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  })
  const [errors, setErrors] = useState({})

  const validate = () => {
    let tempErrors = {}
    let isValid = true

    if (!formData.name.trim()) {
      tempErrors.name = "Name is required"
      isValid = false
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (!formData.email) {
      tempErrors.email = "Email is required"
      isValid = false
    } else if (!emailRegex.test(formData.email)) {
      tempErrors.email = "Email is not valid"
      isValid = false
    }

    if (!formData.message.trim()) {
      tempErrors.message = "Message is required"
      isValid = false
    } else if (formData.message.trim().length < 10) {
      tempErrors.message = "Message must be at least 10 characters"
      isValid = false
    }

    setErrors(tempErrors)
    return isValid
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (validate()) {
      try {
        await axios.post("https://rajasekarinit.com/submit-form.php", formData)
        alert("Message sent successfully!")
        setFormData({
          name: "",
          email: "",
          message: "",
        })
        setErrors({})
      } catch (error) {
        alert("Error sending message.")
      }
    }
  }

  return (
    <>
      <div className="row">
        {/* Contact Form Column  */}
        <div className="col-md-8">
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label for="name" className="form-label">
                Name
              </label>
              <input type="text" className="form-control" id="name" name="name" value={formData.name} onChange={handleChange} required />
              {errors.name && <p style={{ color: "red" }}>{errors.name}</p>}
            </div>
            <div className="mb-3">
              <label for="email" className="form-label">
                Email
              </label>
              <input type="email" className="form-control" id="email" name="email" value={formData.email} onChange={handleChange} required />
              {errors.email && <p style={{ color: "red" }}>{errors.email}</p>}
            </div>
            <div className="mb-3">
              <label for="message" className="form-label">
                Message
              </label>
              <textarea className="form-control" id="message" name="message" rows="4" value={formData.message} onChange={handleChange} required></textarea>
              {errors.message && <p style={{ color: "red" }}>{errors.message}</p>}
            </div>
            <button type="submit" className="btn btn-primary">
              Send
            </button>
          </form>
        </div>
      </div>

      {/* <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="name">Name:</label>
          <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
          {errors.name && <p style={{ color: "red" }}>{errors.name}</p>}
        </div>
        <div>
          <label htmlFor="email">Email:</label>
          <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
          {errors.email && <p style={{ color: "red" }}>{errors.email}</p>}
        </div>
        <div>
          <label htmlFor="message">Message:</label>
          <textarea id="message" name="message" value={formData.message} onChange={handleChange} required />
          {errors.message && <p style={{ color: "red" }}>{errors.message}</p>}
        </div>
        <button type="submit">Submit</button>
      </form> */}
    </>
  )
}

export default ContactForm
