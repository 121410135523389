import React from "react";

export const Projects = () => {
  return (
    <>
      <header class="page-title container col-xxl-8 mb-4 mt-4">
        <h2>Projects</h2>
      </header>

      <div class="container col-xxl-8 mt-3">
        {/* Project 1  */}
        <div class="card mb-4">
          <div class="card-body">
            <h2 class="card-title">Project 1</h2>
            <p class="card-text">
              Description of project 1 goes here. You can provide details about
              the project, technologies used, and any other relevant
              information.
            </p>
          </div>
        </div>

        {/* Project 2 */}
        <div class="card mb-4">
          <div class="card-body">
            <h2 class="card-title">Project 2</h2>
            <p class="card-text">
              Description of project 2 goes here. You can provide details about
              the project, technologies used, and any other relevant
              information.
            </p>
          </div>
        </div>

        {/* Add more projects as needed */}
      </div>
    </>
  );
};
